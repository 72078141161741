import Component, { ComponentConfig } from 'scripts/core/component';
import { CommonComponents } from '../commonTypes';
import gsap from 'gsap';
import { scrollToTop } from 'scripts/utils/scrollToTop';

class Footer extends Component {
    constructor(config: ComponentConfig) {
        super(config);
    }

    doSetup() {
        const toTop = this.element.querySelector('#scroll-top');

        toTop.addEventListener('click', (e) => {
            e.preventDefault();
            scrollToTop();
        });
    }

    resize() {
        // TODO
    }

    public async show() {
        gsap.to(this.element, { autoAlpha: 1 });
    }

    public async hide() {
        gsap.to(this.element, { autoAlpha: 0 });
    }
}

(async () => {
    const el = document.getElementById(CommonComponents.Footer);
    if (!el) {
        return;
    }

    await new Footer({ el }).setup();
})();
