import Component, { ComponentConfig } from 'scripts/core/component';
import { CommonComponents } from '../commonTypes';
import gsap from 'gsap';

class Subscribe extends Component {
    constructor(config: ComponentConfig) {
        super(config);
    }

    doSetup() {
        const form = document.querySelector('#subscribe');
        const emailField = document.querySelector('#subscribe-input');
        const button = document.querySelector('#subscribe-button');

        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        button.addEventListener('click', function(e) {
            e.preventDefault();

            const email = (<HTMLInputElement>emailField).value;

            if (validateEmail(email)) {
                // send form
            } else {
                form.classList.add('error');
            }
        });

        emailField.addEventListener('input', () => {
            form.classList.remove('error');
        });
    }

    resize() {
        // TODO
    }

    public async show() {
        gsap.to(this.element, { autoAlpha: 1 });
    }

    public async hide() {
        gsap.to(this.element, { autoAlpha: 0 });
    }
}

(async () => {
    const el = document.getElementById(CommonComponents.Subscribe);
    if (!el) {
        return;
    }

    await new Subscribe({ el }).setup();
})();
