import { oneTimeSubscription } from '@zajno/common/observing/event';
import logger from 'common/logger';
import type { Component, ComponentConfig } from 'scripts/core/component';
import { PageReadyEvent } from 'scripts/modules/pageEvents';

export const setupManyComponents = (component, elements: HTMLElement[]) => {
    elements.forEach(s => new component({ el: s }).setup());
};

type ComponentCtor<TComp extends Component> = {
    new (options: ComponentConfig): TComp;
};

type StartFunction = () => (Promise<void> | void);
type ISection = Component & { start?: StartFunction};

type Options = {
    id: string;
    start?: setupSection.StartType | StartFunction;
};

export async function setupSection<TComp extends ISection>(
    Ctor: ComponentCtor<TComp>,
    options: Options,
) {
    const {
        id,
        start: start = setupSection.StartType.PageReady,
    } = options;
    const el = document.getElementById(id);
    if (!el) {
        logger.warn(`[setupSection] Section for "${id}" not found, skipped.`);
        return null;
    }

    try {
        const result = await new Ctor({ el }).setup();

        if (start === 'pageReady') {
            await oneTimeSubscription(PageReadyEvent);
        }

        const startFn = typeof start === 'function'
            ? start
            : result.start.bind(result);

        await startFn();

        return result;
    } catch (err) {
        logger.error(`[setupSection] Error while setting up section "${id}":`, err);
        return null;
    }
}

export namespace setupSection {
    export enum StartType {
        Instant = 'instant',
        PageReady = 'pageReady',
    }
}
