import Component, { ComponentConfig } from 'scripts/core/component';
import { CommonComponents } from '../commonTypes';
import gsap from 'gsap';
import { ScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { Breakpoints } from 'common/views/appBreakpoints';
import { BODY_OVERFLOW_CLASS } from 'scripts/utils/constants';
import { scrollToTop } from 'scripts/utils/scrollToTop';
import { setupSection } from 'scripts/utils/setupComponents';

class Header extends Component {
    constructor(config: ComponentConfig) {
        super(config);
    }

    doSetup() {
        const widthScreen = window.innerWidth;

        const menu = this.element.querySelector('.header-menu');

        // TODO use explicit breakpoint types
        if (widthScreen > Breakpoints.All.Tablet.width) {
            ScrollTrigger.observe({
                target: window,
                type: 'wheel,touch,scroll,pointer',
                onUp: () => {
                    if (!document.body.classList.contains(BODY_OVERFLOW_CLASS)) {
                        gsap.set(menu, { pointerEvents: 'auto' });
                        gsap.to(menu, {
                            alpha: 1,
                            duration: 0.4,
                        });
                    }
                },
                onDown: () => {
                    if (!document.body.classList.contains(BODY_OVERFLOW_CLASS)) {
                        gsap.set(menu, { pointerEvents: 'none' });
                        gsap.to(menu, {
                            alpha: 0,
                            duration: 0.4,
                        });
                    }
                },
            });
        }

        // listen to link button clicks, and if the link is the same as the current page, scroll to the top
        const links = this.element.querySelectorAll<HTMLAnchorElement>('.header-wrap a');
        links.forEach((link) => {
            link.addEventListener('click', (e) => {
                const href = link?.getAttribute('href');
                if (href && href != '#' && href === window.location.pathname) {
                    e.preventDefault();
                    scrollToTop();
                }
            });
        });

        this.hide();
    }

    public start() {
        this.show();
    }

    resize() {
        // TODO
    }

    public async show() {
        gsap.to(this.element, { autoAlpha: 1 });
    }

    public async hide() {
        gsap.to(this.element, { autoAlpha: 0 });
    }
}

setupSection(Header, { id: CommonComponents.Header, start: setupSection.StartType.PageReady });
