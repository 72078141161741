import { ScrollSmoother } from 'scripts/lib/gsap/scrollSmoother';

export default function anchors(enableScroll: boolean, mobileMenu?, smooth?: boolean) {

    const anchors: NodeListOf<HTMLElement> = document.querySelectorAll('.anchor-item');
    const smoother = ScrollSmoother?.get();

    const calculateScrollPosition = (element) => {
        const top = element.getBoundingClientRect().y + window.scrollY;
        return top;
    };

    anchors.forEach(anchor => {
        if (enableScroll) {
            anchor.addEventListener('click', e => {
                e.preventDefault();
                const element = document.querySelector(`#${anchor.dataset.anchor}`);
                const scrollPosition = window.scrollY;
                if (element.getBoundingClientRect().y + window.scrollY - window.innerHeight === scrollPosition) {
                    return;
                }
                const top = calculateScrollPosition(element);

                if (mobileMenu.isOpen){
                    mobileMenu.toggleState();
                }

                if (!smoother) {
                    window.scrollTo({
                        top: top,
                        behavior: smooth ? 'smooth' : 'instant',
                    });
                } else {
                    smoother.scrollTo(`#${anchor.dataset.anchor}`, smooth);
                    // The position of the element is calculated incorrectly, a recalculation is needed when sub-scrolling occurs
                    smoother.scrollTo(`#${anchor.dataset.anchor}`, smooth, 'top+=5px');
                }
            });
        }
    });
}
