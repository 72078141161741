import Component, { ComponentConfig } from 'scripts/core/component';
import { CommonComponents } from '../commonTypes';
import gsap from 'gsap';
import { inFrames } from 'scripts/utils/inFrames';
// import { activateBurger, deactivateBurger } from 'scripts/modules/mobile-menu';
import { Easings } from 'scripts/utils/tweenHelpers';
import { BODY_OVERFLOW_CLASS } from 'scripts/utils/constants';
import anchors from 'scripts/modules/anchors';
import { Breakpoints } from 'common/views/appBreakpoints';

class MobileMenu extends Component {
    private _burgers: NodeListOf<HTMLElement>;
    private _menu: HTMLElement;
    private _menuHeader: HTMLElement;
    private _menuItems: NodeListOf<HTMLElement>;
    private _openMenuTimeline: gsap.core.Timeline;
    private _activeMenu: boolean;

    private get _dropButton() { return document.querySelectorAll('.mobile-menu__drop'); }

    constructor(config: ComponentConfig) {
        super(config);
    }

    setupDropdown = (el: HTMLElement) => {
        const _dropContent = el.querySelector('.mobile-menu__drop-content');
        const _dropButtonArrow = el.querySelector('.mobile-menu__drop svg');


        const tlDrop = gsap.timeline({ paused: true })
            .to(_dropContent, {
                height: 'auto',
                opacity: 1,
                y: 0,
                duration: inFrames(40),
                ease: Easings.customEaseInOut,
            })
            .to(_dropButtonArrow, {
                scaleY: -1,
                duration: inFrames(10),
            }, '<');

        el.addEventListener('click', (e) => {
            el.classList.toggle('active');
            _dropContent.classList.toggle('active');

            if (el.classList.contains('active')) {
                tlDrop.play();
            } else {
                tlDrop.reverse();
            }
            e.stopPropagation();
        });
    };

    async menuOpen() {
        this._openMenuTimeline = gsap.timeline({
            paused: true,
        })
            .to(this._menu, {
                x: 0,
                duration: inFrames(40),
                ease: Easings.customEaseInOut,
            })
            .to(this._menuHeader, {
                x: 0,
                duration: inFrames(40),
                ease: Easings.customEaseInOut,
            }, '<')
            .to(this._menu, {
                borderRadius: 0,
                duration: inFrames(10),
                ease: Easings.customEaseInOut,
            }, inFrames(30))
            .to(this._menuItems, {
                x: 0,
                duration: inFrames(10),
                stagger: inFrames(1),
                ease: 'linear',
            }, `>-${inFrames(15)}`);
    }

    async _openMenu() {
        if (!this._openMenuTimeline) {
            this.menuOpen();
        }
        this._openMenuTimeline.play();
        this._activeMenu = true;
    }

    async _closeMenu() {
        this._openMenuTimeline.reverse();
        this._activeMenu = false;
    }

    async activateBurger() {
        this._burgers.forEach(burgerItem => {
            burgerItem.classList.add('active');
        });

        this._menu.classList.add('active');
        document.body.classList.add(BODY_OVERFLOW_CLASS);
        this._openMenu();
    }

    async deactivateBurger() {
        this._burgers.forEach(burgerItem => {
            burgerItem.classList.remove('active');
        });

        this._menu.classList.remove('active');
        document.body.classList.remove(BODY_OVERFLOW_CLASS);
        this._closeMenu();
    }

    public get isOpen() {
        return this._activeMenu;
    }

    public toggleState() {
        if (this._activeMenu) {
            this.deactivateBurger();
        } else {
            this.activateBurger();
        }
    }

    doSetup() {
        this._burgers = document.querySelectorAll('.header-burger');
        this._menu = document.querySelector('.mobile-menu');
        this._menuHeader = document.querySelector('.mobile-menu__header');
        this._menuItems = document.querySelectorAll('.mobile-menu__animation-item');

        this._dropButton.forEach((el) => {
            this.setupDropdown(el as HTMLElement);
        });

        this._burgers.forEach((burger) => {
            burger.addEventListener('click', () => {
                if (burger.classList.contains('active')) {
                    this.deactivateBurger();
                } else {
                    this.activateBurger();
                }
            });
        });
    }

    resize() {
        // TODO
    }

    public async show() {
        gsap.to(this.element, { autoAlpha: 1 });
    }

    public async hide() {
        gsap.to(this.element, { autoAlpha: 0 });
    }
}


(async () => {
    const el = document.getElementById(CommonComponents.MobileMenu);
    if (!el) {
        return;
    }

    const menu = await new MobileMenu({ el }).setup();
    anchors(true, menu, !Breakpoints.isDesktop);
})();


