import Component, { ComponentConfig } from 'scripts/core/component';
import gsap from 'gsap';
import { ScrollSmoother } from 'scripts/lib/gsap/scrollSmoother';
import { CommonComponents } from '../commonTypes';
import { LazyQueue } from 'scripts/components/lazy/lazyLoadComponent';
import { oneTimeSubscription } from '@zajno/common/observing/event';
import { setTimeoutAsync, timeoutPromise } from '@zajno/common/async/timeout';
import { PRELOADER_ACTIVE_CLASS } from 'scripts/utils/constants';
import { PreloadEvent } from 'scripts/modules/pageEvents';
import { scrollToTop } from 'scripts/utils/scrollToTop';
import { Breakpoints } from 'common/views/appBreakpoints';

const MAX_PROGRESS = 98;
export const MAX_WAIT_TIME = 10_000;
export const MIN_WAIT_TIME = 1_000;

const TARGET_LAZY_PRIORITY = 10;
const TARGET_LAZY_PRIORITY_MOBILE = 20;

class Preloader extends Component {
    private _smoother: ScrollSmoother;
    private _progressInterval;

    constructor(config: ComponentConfig, smoother?: ScrollSmoother) {
        super(config);

        this._smoother = smoother || ScrollSmoother.get();
    }

    private get _bar() { return this.element.querySelector<HTMLDivElement>('.preloader__bar'); }
    private get _fillLine() { return this.element.querySelector<HTMLDivElement>('.preloader__line'); }
    private get _percent() { return this.element.querySelector('.preloader__percent span'); }

    doSetup() {
        this.disableScroll();
    }

    public async show() {
        gsap.to(this.element, { autoAlpha: 1 });
    }

    public async hide() {
        const tl = gsap.timeline();
        clearInterval(this._progressInterval);

        await tl
            .to(this._fillLine, { width: '100%', duration: 0.5, ease: 'none' })
            .to(this._percent, { innerHTML: 100, duration: 0.5, onUpdate: () => {
                this._percent.innerHTML = String(Math.round(Number(this._percent.innerHTML)));
            } }, 0)
            .to(this.element, { autoAlpha: 0, duration: 0.5 });
        this.enableScroll();
    }

    public startProgress = (duration: number) => {
        const step = duration / 100;
        let percentProgress = Number(this._percent.innerHTML);
        let barProgress = (this._fillLine.offsetWidth / this._bar.offsetWidth) * 100;

        this._progressInterval = setInterval(() => {
            percentProgress++;
            barProgress++;

            this._percent.innerHTML = String(percentProgress);

            if (barProgress < MAX_PROGRESS) {
                gsap.to(this._fillLine, { width: `${barProgress}%`, duration: 0.1, ease: 'none' });
            }

            if (percentProgress >= MAX_PROGRESS) {
                clearInterval(this._progressInterval);
            }
        }, step);
    };

    private enableScroll = () => {
        document.body.classList.remove(PRELOADER_ACTIVE_CLASS);
        this._smoother?.normalizer?.enable();
    };

    private disableScroll = () => {
        document.body.classList.add(PRELOADER_ACTIVE_CLASS);
        this._smoother?.normalizer?.disable();

        scrollToTop();

        gsap.to(this.element, { autoAlpha: 1, duration: 0 });
    };
}

(async () => {
    const el = document.getElementById(CommonComponents.Preloader);
    if (!el) {
        PreloadEvent.trigger();
        return;
    }

    const preloader = await new Preloader({ el }).setup();

    preloader.startProgress(MAX_WAIT_TIME);

    const targetLazyPriority = Breakpoints.isDesktop
        ? TARGET_LAZY_PRIORITY
        : TARGET_LAZY_PRIORITY_MOBILE;
    const nextTargetLazyPriority = targetLazyPriority + 1;

    // add dummy lazy task for target priority just to 100% get event for it
    LazyQueue.enqueue(() => Promise.resolve(), targetLazyPriority);

    // pause all items after target priority preloader exit smoother
    LazyQueue.enqueue(() => setTimeoutAsync(2000), nextTargetLazyPriority);

    timeoutPromise(oneTimeSubscription(LazyQueue.afterPriorityRun, p => p >= targetLazyPriority), MAX_WAIT_TIME, MIN_WAIT_TIME)
        .then(async ({ timedOut, elapsed }) => {

            if (timedOut) {
                // eslint-disable-next-line no-console
                console.warn('Preloader timed out, elapsed =', elapsed);
            }

            await preloader.hide();
            PreloadEvent.trigger();
        });
})();
